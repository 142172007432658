// 俄语
export default {
  loginPage:{
    mobileNumber:'телефонный номер',
    password:'пароль',
    forgotPassword:'Забыли пароль',
    goToRegister:'зарегистрироваться',
    rememberPassword:'напомнить пароль',
    login:'Авторизоваться',
    noAccountYet:'У вас еще нет аккаунта?',
    mobileNumberPlaceholder:'Пожалуйста, введите номер Вашего мобильного телефона',
    mobileNumberMessage:'Пожалуйста, введите стандартный номер мобильного телефона',
    passwordPlaceholder:'Пожалуйста, введите пароль',
    passwordMessage:'Вы ввели неправильный пароль, пожалуйста, повторите попытку',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  //   注册页
  registerPage:{
    register:'  регистр ',
    mobileNumber:'телефонный номер',
    mobileNumberPlaceholder :'Пожалуйста, введите номер телефона',
    mobileNumberMessage:'Пожалуйста, введите номер телефона',
    loginPassword:'логин Пароль',
    loginPasswordPlaceholder:'Пожалуйста, введите пароль',
    loginPasswordMessage:'Пожалуйста, введите пароль',
    invitationCode:'Код приглашения',
    invitationCodePlaceholder:'Пожалуйста, введите код приглашения',
    invitationCodeMessage:'Пожалуйста, введите код приглашения',
    fundingPassword:'Пароль фонда',
    fundingPasswordPlaceholder:'Пожалуйста, введите пароль фонда',
    fundingPasswordMessage:'Пожалуйста, введите пароль фонда',
    iKnowAndAgree:'я знаю и согласен',
    accountOpeningAgreement:'"Соглашение об открытии счета"',
    theTreaties:'договоры',
    signUpNow:'Зарегистрироваться сейчас',
    pleaseAgree:'Пожалуйста, сначала согласитесь с соглашением о регистрации',
    existingAccount:'Иметь аккаунт',
    loginNow:'""Войти сейчас""',
    registrationFailed:'Не удалось зарегистрироваться',
    registrationSuccessful:'Регистрация прошла успешно',
    iKnow:'Я знаю',
    accountOpen:'Соглашение об открытии счета'
  },
  // 主页页面
  indexPage:{
    indexList:'Не удалось получить данные домашней страницы',
    amazonLetMiaogouOpenYourWayToWealth:'AmazonПозвольте Мяогоу открыть вам путь к богатству',
    myAssets:'мои активы($)',
    recharge:'перезарядка',
    withdrawal:'вывод',
    yesterdaysIncome:'вчерашний заработок($)',
    cumulativeIncome:'совокупный доход($)',
    todaysIncome:'Заработок сегодня($)',
    freezeFunds:'Заморозить средства($)',
    regularFinancialManagement:'Регулярный финансовый менеджмент',
    quickRecharge:'Быстрая перезарядка',
    quickWithdrawal:'Быстрый вывод',
    inviteFriends:'пригласить друзей',
    taskHall:'Миссионерский зал',
    unlocked:'Разблокирован',
	service:'Обслуживание клиентов',
	leaderboard:'Показать'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'Вторая запись о покупке',
    this:'Эти данные предоставлены крупными кооперативными чиновниками.',
    Remaining:'оставшиеся свободные активы($)',
    allOrders:'все заказы',
    toBeProcessed:'все заказы',
    completed:'завершенный',
    freezing:'Замораживание',
    totalAmountOfTheOrder:'Общий баланс заказа',
    commission:'комиссия',
    Estimated:'Предполагаемые скидки',
    submitOrder:'Отправить заказ',
    secondpurchasetime:'Секунды, чтобы купить время:',
    secondPurchaseNumber:'Количество секунд покупки:',
    NotSubmitted:'Не отправлять в настоящее время',
    submitNow:'Отправить сейчас                   '
  },
  // order页面
  orderPage:{
    grabAnOrder:'Захват ордеров нон-стоп',
    hiShop:'Привет Магазин',
    winTheFutureWithYou:'Беспроигрышное будущее с вами',
    orderText:'Основанная в 1994&nbsp;году, Amazon Marketplace работает с более чем 1 миллионом компаний электронной коммерции и обрабатывает от 150 000 до 30 миллионов заказов в день. Поскольку управление фондом доверено компании по управлению активами, это позволяет избежать запросов на возврат средств пользователями и бегства капитала от компаний электронной коммерции, а интеллектуальные облачные клиенты и IP-адреса пользователей используются в соответствии с установленными правилами. Автоматический конкурс заказов предотвратит блокировку, понижение или блокировку продавцов из-за ошибок пользователей, которые захватывают заказы. В качестве посредника мы продолжим стремиться к повышению доверия и эффективности продавцов и пользователей при конкуренции за заказы!',
    bronzeMember:'Бронзовый член',
    commission:'Комиссия ({rate}%)',
    Matching:'Подбор заказов для Вас, пожалуйста, подождите минутку',
    dispatch:'Отправка',
    grabAnOrder:'Захват заказов',
    balance:'равновесие',
    recharge:'перезаряжать',
    withdrawal:'Снятия',
    matchingRules:'Соответствие правилу',
    commissionEarnedToday:'Комиссия, заработанная сегодня',
    Today:'Количество коэффициентов было захвачено сегодня',
    oddNumber:'Единственное число, оставшееся сегодня',
    yesterdays:'Вчерашняя комиссия',
    User:'пользователь',
    getCommission:'Сдать в эксплуатацию'
  },
  // my页面
  myPage:{
    logout:'Выйти',
    areYouSureYouWantToLogOut:'Вы уверены, что выходите из системы?',
    confirnText:'Уверен',
    cancleText:'Отмена',
    amazonReputation:'Репутация Amazon:👑',
    accountBalance:'Счете',
    recharge:'перезаряжать',
    withdrawal:'Снятия',
    deliveryAddress:'Адрес доставки',
    iWantToPromote:'Я хочу продвигать',
    secondPurchaseRecord:'Я хочу продвигать',
    accountDetails:'Реквизиты счета',
    teamReport:'Отчеты команд',
    companyQualification:'Квалификация компании',
	certificate: 'сертификат'
  },
  accountDetailsPage:{
    accountDetails:'Реквизиты счета',
    startDate:'Дата начала',
    endDate:'Дата окончания',
    search:'Искать',
    alltypes:'Все типы',
    withdrawalRecord:'История вывода средств',
    rechargeRecord:'Записи пополнения счета',
    withDrawal:'Снятия',
    UAddress:'Адрес U',
    rebateForOrderGrabbing:'Скидка на захват заказов',
    orderGrab:'Захват заказов',
    recharge:'перезаряжать',
    tryToLoad:'Изо всех сил пытается загрузиться',
    noMore:'Больше нет',
    cancel:'Отмена',
    ok:'Уверен',
    pleaseSelectATime:'Пожалуйста, выберите время'
},
teamPage:{
  teamReport:'Отчеты команд',
  startDate:'Дата начала',
  endDate:'Дата окончания',
  search:'Искать',
  teamBalance:'Баланс команды ($)',
  teamFlow:'Командный поток ($)',
  totalTeamRecharge:' Общее пополнение команды ($)',
  theTotalWithdrawalOfTheTeam:'Общий вывод команды ($)',
  teamOrderCommission:'Комиссия за групповой заказ ($)',
  myCommission:'Моя комиссия($)',
  directPushNumber:'Прямые реферальные руководители',
  teamSize:'Количество членов команды',
  todaysNewNumberOfPeople: 'Сегодняшнее новое число',
  todaysActiveNumber:'Количество активных людей сегодня',
  level1:'Уровень',
  level2:'Уровень 2',
  level3:'Третий уровень',
  tryToLoad:'Изо всех сил пытается загрузиться',
  noMore:'Больше нет',
  mobilePhone:'Номер мобильного телефона',
  Number:'Количество промоутеров',
  withdrawals:'Снятия',
  recharge:'перезаряжать',
  time:'Время регистрации'
},
culturePage:{
  title:'Квалификация компании'
},
// 抢单列表页面
grabListPage:{
  failedToGetTheOrderGrabList:'Не удалось получить список захвата заказов'
},
// 提现页面
withDrawalPage:{
  withDrawal:'Снятия',
  currentBalance:'Текущая сумма ($)',
  withdrawalApplicationInProgress:'Запрос на вывод средств в ожидании ($)',
  cumulativelyWithdrawn:'Накопительный вывод средств($)',
  withdrawalAmount:'Сумма вывода',
  pleaseEnterTheWithdrawalAmount:'Пожалуйста, введите сумму вывода',
  withdrawAll:'Вывести все',
  uAddress:'Адрес U',
  pleaseEnterUAddress:'Пожалуйста, введите адрес U',
  transactionPassword:'Торговые пароли',
  pleaseEnterTheTransactionPassword:'Пожалуйста, введите пароль транзакции',
  withdrawNow:'Немедленное снятие средств',
  allFieldsCannotBeEmpty:'Все поля не могут быть пустыми',
  able:'Сумма наличных денег, подлежащих снятию'
},
addressPage:{
  address:'адрес',
  realName:'Настоящее имя',
  pleaseEnterYourRealName:'Пожалуйста, введите Ваше настоящее имя',
  mobileNumber:'Номер мобильного телефона',
  pleaseEnterThePhoneNumber:'Пожалуйста, введите номер Вашего мобильного телефона',
  region:'область',
  pleaseEnterTheRegion:'Пожалуйста, введите регион',
  detailedAddress:'Полный адрес',
  pleaseEnterDetailedAddress:'Пожалуйста, введите Ваш полный адрес',
  submit:'Отправить'
},
// Popularize页面
popularizePage:{
  iWantToPromote:'Я хочу продвигать',
  inviteNewUsers:'Приглашение новых пользователей',
  winTheFutureWithYou:'Беспроигрышное будущее с вами',
  screenshot:'Сделайте снимок экрана, чтобы сохранить QR-код',
  friend:'Идентификация и регистрация друга',
  complete:'Завершите процедуру регистрации',
  distribute:'Красные конверты будут розданы в течение 24 часов',
  invitationCode:'Код приглашения:'
},
// 充值页面
rechargePage:{
  recharge:'перезаряжать',
  currentBalance:'Текущий баланс($)',
  rechargeApplication:'Приложение для пополнения счета в процессе($)',
  accumulatedRecharged:'Накопленное пополнение($)',
  rechargeAmount:'Сумма пополнения',
  pleaseEnterTheRechargeAmount:'Пожалуйста, введите сумму пополнения',
  rechargeNow:'Пополнить сейчас'
},
	vipPage:{
		'vip_level': 'VIP-уровень',
		'current_level': 'Текущий уровень',
		'vip_text1': '{order_num} заказов можно получить в день',
		'vip_text2': '{rate}% прибыли с заказа',
		'vip_text3': 'Активируется один депозит {recharge} USDT',
	}
}
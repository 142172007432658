// 越南
export default {
  loginPage:{
    mobileNumber:'Số điện thoại di động',
    password:'mật khẩu',
    forgotPassword:'Quên mật khẩu',
    goToRegister:'Đi để đăng ký',
    rememberPassword:'nhớ mật khẩu',
    login:'đăng nhập',
    noAccountYet:'Chưa có tài khoản?',
    mobileNumberPlaceholder:'Vui lòng nhập số điện thoại di động của bạn',
    mobileNumberMessage:'Vui lòng nhập số điện thoại di động của bạn',
    passwordPlaceholder:'Vui lòng nhập mật khẩu',
    passwordMessage:'Vui lòng nhập mật khẩu',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  //   注册页
  registerPage:{
    register:'  đăng ký ',
    mobileNumber:'Số điện thoại di động',
    mobileNumberPlaceholder :'Vui lòng nhập số điện thoại',
    mobileNumberMessage:'Vui lòng nhập số điện thoại',
    loginPassword:'mật khẩu đăng nhập',
    loginPasswordPlaceholder:'Vui lòng nhập mật khẩu đăng nhập',
    loginPasswordMessage:'Vui lòng nhập mật khẩu đăng nhập',
    invitationCode:'Mã lời mời',
    invitationCodePlaceholder:'Vui lòng nhập mã mời',
    invitationCodeMessage:'Vui lòng nhập mã mời',
    fundingPassword:'Mật khẩu tài trợ',
    fundingPasswordPlaceholder:'Vui lòng nhập mật khẩu quỹ',
    fundingPasswordMessage:'Vui lòng nhập mật khẩu quỹ',
    iKnowAndAgree:'Tôi biết và đồng ý',
    accountOpeningAgreement:'"Thỏa thuận mở tài khoản"',
    theTreaties:'Các hiệp ước',
    signUpNow:'Đăng ký ngay',
    pleaseAgree:'Vui lòng đồng ý với thỏa thuận đăng ký trước',
    existingAccount:'tài khoản hiện có',
    loginNow:'"Đăng nhập ngay bây giờ"',
    registrationFailed:'Đăng ký thất bại',
    registrationSuccessful:'Đăng ký thành công',
    iKnow:'Tôi biết',
    accountOpen:'Thỏa thuận mở tài khoản'
  },
  // 主页页面
  indexPage:{
    indexList:'Lấy dữ liệu trang chủ thất bại',
    amazonLetMiaogouOpenYourWayToWealth:'Amazon cho phép mua giây để mở đường cho sự giàu có của bạn',
    myAssets:'Tài sản của tôi($)',
    recharge:'Nạp tiền',
    withdrawal:'Rút tiền mặt',
    yesterdaysIncome:'Lợi nhuận của ngày hôm qua($)',
    cumulativeIncome:'Lợi nhuận tích lũy($)',
    todaysIncome:'Lợi nhuận hôm nay($)',
    freezeFunds:'Đóng băng các quỹ($)',
    regularFinancialManagement:'Quản lý tài chính thường xuyên',
    quickRecharge:'Nạp tiền nhanh',
    quickWithdrawal:'Rút tiền nhanh chóng',
    inviteFriends:'Mời bạn bè',
    taskHall:'Hội trường nhiệm vụ',
    unlocked:'Đã được mở khóa',
	service:'Dịch vụ',
	leaderboard:'Rút tiền'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'Hồ sơ mua hàng giây',
    this:'Dữ liệu này được cung cấp bởi các quan chức hợp tác lớn',
    Remaining:'Tài sản còn lại có sẵn($)',
    allOrders:'Tất cả các đơn đặt hàng',
    toBeProcessed:'Đang chờ xử lý',
    completed:'Nó đã hoàn tất',
    freezing:'Đóng băng',
    totalAmountOfTheOrder:'Tổng số dư đơn đặt hàng',
    commission:'Hoa hồng',
    Estimated:'Dự kiến sẽ trở lại làm việc',
    submitOrder:'Gửi đơn đặt hàng',
    secondpurchasetime:'Thời gian mua giây:',
    secondPurchaseNumber:'Số mua giây:',
    NotSubmitted:'Không nộp',
    submitNow:'Gửi ngay bây giờ'
  },
  // order页面
  orderPage:{
    grabAnOrder:'Cướp đơn không ngừng',
    hiShop:'Xin chào mua',
    winTheFutureWithYou:'Cùng bạn giành chiến thắng trong tương lai',
    orderText:'Được thành lập vào năm 1994&nbsp;, Amazon Marketplace làm việc với hơn 1 triệu công ty thương mại điện tử để xử lý từ 150.000 đến 30 triệu đơn đặt hàng mỗi ngày. Vì quản lý tiền được ủy thác cho các công ty quản lý tài sản, có thể tránh được yêu cầu hoàn tiền của người dùng và các công ty thương mại điện tử chạy trốn tiền, khách hàng đám mây thông minh và IP người dùng được sử dụng theo các quy tắc được thiết lập. Cuộc thi đặt hàng tự động sẽ ngăn chặn các thương gia bị chặn, hạ cấp và niêm phong do lỗi của người dùng đơn đặt hàng. Là một trung gian, chúng tôi sẽ tiếp tục làm việc chăm chỉ để cải thiện sự tin tưởng và hiệu quả của người bán và người dùng trong việc cạnh tranh cho đơn đặt hàng!',
    bronzeMember:'Thành viên đồng',
    commission:'Hoa hồng ({rate}%)',
    Matching:'Đang khớp đơn đặt hàng cho bạn, vui lòng sau',
    dispatch:'Gửi đơn đặt hàng',
    grabAnOrder:'Cướp đơn đặt hàng',
    balance:'Số dư',
    recharge:'Nạp tiền',
    withdrawal:'Rút tiền mặt',
    matchingRules:'Khớp quy tắc',
    commissionEarnedToday:'Hoa hồng đã được thực hiện ngày hôm nay',
    Today:'Hôm nay đã cướp được số lượng đơn',
    oddNumber:'Số lượng đơn còn lại hôm nay',
    yesterdays:'Hoa hồng của ngày hôm qua',
    User:'Người dùng',
    getCommission:'Nhận hoa hồng'
  },
  // my页面
  myPage:{
    logout:'Đăng xuất khỏi đăng nhập',
    areYouSureYouWantToLogOut:'Bạn có chắc chắn không muốn đăng xuất không?',
    confirnText:'Ok',
    cancleText:'Hủy bỏ',
    amazonReputation:'Amazon Uy tín:👑',
    accountBalance:'Số dư tài khoản',
    recharge:'Nạp tiền',
    withdrawal:'Rút tiền mặt',
    deliveryAddress:'Địa chỉ nhận hàng',
    iWantToPromote:'Tôi muốn quảng bá nó',
    secondPurchaseRecord:'Hồ sơ mua hàng giây',
    accountDetails:'Chi tiết tài khoản',
    teamReport:'Báo cáo nhóm',
    companyQualification:'Trình độ của công ty',
	certificate: 'chứng chỉ'
  },
  addressPage:{
    address:'Địa chỉ',
    realName:'Tên thật',
    pleaseEnterYourRealName:'Vui lòng nhập tên thật của bạn',
    mobileNumber:'Số điện thoại di động',
    pleaseEnterThePhoneNumber:'Vui lòng nhập số điện thoại di động',
    region:'khu vực',
    pleaseEnterTheRegion:'Vui lòng nhập khu vực',
    detailedAddress:'Địa chỉ chi tiết',
    pleaseEnterDetailedAddress:'Vui lòng nhập địa chỉ chi tiết',
    submit:'Gửi'
  },
  // Popularize页面
  popularizePage:{
    iWantToPromote:'Tôi muốn quảng bá nó',
    inviteNewUsers:'Mời người dùng mới',
    winTheFutureWithYou:'Cùng bạn giành chiến thắng trong tương lai',
    screenshot:'Ảnh chụp màn hình lưu mã QR',
    friend:'Bạn bè nhận ra và đăng ký',
    complete:'Hoàn tất đăng ký để lấy phiếu',
    distribute:'Lì xì bằng tiền mặt được phát hành trong vòng 24 giờ',
    invitationCode:'Mã mời:'
  },
  // 抢单列表页面
  grabListPage:{
    failedToGetTheOrderGrabList:'Danh sách cướp đơn không thành công'
  },
  accountDetailsPage:{
    accountDetails:'Chi tiết tài khoản',
    startDate:'Ngày bắt đầu',
    endDate:'Ngày kết thúc',
    search:'Tìm kiếm',
    alltypes:'Tất cả các loại',
    withdrawalRecord:'Hồ sơ rút tiền',
    rechargeRecord:'Ghi nạp tiền',
    withDrawal:'Rút tiền mặt',
    UAddress:'Địa chỉ U',
    rebateForOrderGrabbing:'Cướp đơn trả lại người giúp việc',
    orderGrab:'Cướp đơn đặt hàng',
    recharge:'Nạp tiền',
    tryToLoad:'Cố gắng tải',
    noMore:'Không có gì nhiều hơn',
    cancel:'Hủy bỏ',
    ok:'Ok',
    pleaseSelectATime:'Vui lòng chọn thời gian'
},
teamPage:{
  teamReport:'Báo cáo nhóm',
  startDate:'Ngày bắt đầu',
  endDate:'Ngày kết thúc',
  search:'Tìm kiếm',
  teamBalance:'Số dư nhóm($)',
  teamFlow:'Nhóm chảy nước($)',
  totalTeamRecharge:'Tổng số tiền nạp của nhóm($)',
  theTotalWithdrawalOfTheTeam:'Tổng số tiền rút ra của nhóm($)',
  teamOrderCommission:'Hoa hồng đơn đặt hàng nhóm($)',
  myCommission:'Hoa hồng của tôi($)',
  directPushNumber:'Đẩy số lượng trực tiếp',
  teamSize:'Số lượng đội',
  todaysNewNumberOfPeople: 'Số lượng người mới ngày hôm nay',
  todaysActiveNumber:'Số lượng người hoạt động ngày hôm nay',
  level1:'Cấp 1',
  level2:'Cấp độ 2',
  level3:'Cấp 3',
  tryToLoad:'Cố gắng tải',
  noMore:'Không có gì nhiều hơn',
  mobilePhone:'Số điện thoại di động',
  Number:'Số lượng người quảng bá',
  withdrawals:'Rút tiền mặt',
  recharge:'Nạp tiền',
  time:'Thời gian đăng ký'
},
culturePage:{
  title:'Trình độ của công ty'
},
// 充值页面
rechargePage:{
  recharge:'Nạp tiền',
  currentBalance:'Số dư hiện tại($)',
  rechargeApplication:'Trong đơn xin nạp tiền($)',
  accumulatedRecharged:'Tích lũy đã được nạp tiền($)',
  rechargeAmount:'Số tiền nạp',
  pleaseEnterTheRechargeAmount:'Vui lòng nhập số tiền nạp',
  rechargeNow:'Nạp tiền ngay lập tức'
},
// 提现页面
withDrawalPage:{
  withDrawal:'Rút tiền mặt',
  currentBalance:'Số tiền hiện tại($)',
  withdrawalApplicationInProgress:'Trong đơn xin rút tiền($)',
  cumulativelyWithdrawn:'Tích lũy đã được rút ra($)',
  withdrawalAmount:'Rút tiền mặt',
  pleaseEnterTheWithdrawalAmount:'Vui lòng nhập số tiền mặt',
  withdrawAll:'Rút tiền tất cả',
  uAddress:'Địa chỉ U',
  pleaseEnterUAddress:'Vui lòng nhập địa chỉ U',
  transactionPassword:'Mật khẩu giao dịch',
  pleaseEnterTheTransactionPassword:'Vui lòng nhập mật khẩu giao dịch',
  withdrawNow:'Rút tiền ngay bây giờ',
  allFieldsCannotBeEmpty:'Tất cả các mục điền không thể trống',
  able:'Số tiền mặt có thể được nâng lên'
},
	vipPage:{
		'vip_level': 'Cấp độ VIP',
		'current_level': 'Cấp độ hiện tại',
		'vip_text1': '{order_num} đơn đặt hàng có thể nhận được mỗi ngày',
		'vip_text2': '{rate}% lợi nhuận trên mỗi đơn hàng',
		'vip_text3': 'Một khoản tiền gửi {recharge} USDT được kích hoạt',
	}
}
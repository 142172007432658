export default {
  loginPage:{
    mobileNumber:'携帯電話番号',
    password:'パスワード',
    forgotPassword:'パスワードを忘れた',
    goToRegister:'登録に移動',
    rememberPassword:'パスワードを覚えておいてください',
    login:'ログイン',
    noAccountYet:'まだアカウントはありませんか?',
    mobileNumberPlaceholder:'携帯電話番号を入力してください',
    mobileNumberMessage:'携帯電話番号を入力してください',
    passwordPlaceholder:'パスワードを入力してください',
    passwordMessage:'パスワードを入力してください',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  // 主页页面
  indexPage:{
    indexList:'トップページデータの取得に失敗しました',
    amazonLetMiaogouOpenYourWayToWealth:'AmazonMiaogouに富への道を開いてもらいましょう',
    myAssets:'マイアセット($)',
    recharge:'リチャージ',
    withdrawal:'引き出し額',
    yesterdaysIncome:'昨日の収入($)',
    cumulativeIncome:'累積収入($)',
    todaysIncome:'今日の収入($)',
    freezeFunds:'資金の凍結($)',
    regularFinancialManagement:'通常の財務管理',
    quickRecharge:'クイックリチャージ',
    quickWithdrawal:'クイックウィズドロー',
    inviteFriends:'友達を招待する',
    taskHall:'ミッションホール',
    unlocked:'ロック解除',
	service:'カスタマーサービス',
	leaderboard:'引き出し'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'2回目の購入記録',
    this:'このデータは主要な協同組合関係者によって提供されています',
    Remaining:'残りの利用可能な資産($)',
    allOrders:'すべての注文',
    toBeProcessed:'処理される',
    completed:'完了',
    freezing:'フリーズ',
    totalAmountOfTheOrder:'注文の合計金額',
    commission:'コミッション',
    Estimated:'推定コミッションリベート',
    submitOrder:'注文を送信',
    secondpurchasetime:'2回目の購入時間:',
    secondPurchaseNumber:'2番目の購入番号:',
    NotSubmitted:'まだ提出されていません',
    submitNow:'今すぐ送信'
  },
  // order页面
  orderPage:{
    grabAnOrder:'こんにちはショッピング',
    hiShop:'こんにちは購入',
    winTheFutureWithYou:'あなたと一緒に未来を勝ち取ろう',
    orderText:'1994年に設立されたAmazonマーケットプレイスは、100万を超えるeコマース企業と連携し、1日あたり15万から3000万の注文を処理しています。資金管理は資産運用会社に委託しているため、利用者の払い戻し請求や電子商取引会社の資本逃避を回避でき、設定されたルールに従ってインテリジェントクラウドクライアントと利用者IPを利用しています。自動注文競争は、注文を取得するユーザーによるミスによってマーチャントが禁止、ダウングレード、または禁止されるのを防ぎます。仲介業者として、今後も販売者やユーザーの注文競争における信頼と効率の向上に努めていきます！',
    bronzeMember:'ブロンズメンバー',
    commission:'コミッション({rate}%)',
    Matching:'注文は後で一致します',
    dispatch:'ディスパッチ',
    grabAnOrder:'注文を取得する',
    balance:'残高',
    recharge:'リチャージ',
    withdrawal:'引き出し額',
    matchingRules:'マッチングルール',
    commissionEarnedToday:'今日獲得したコミッション',
    Today:'今日は注文数を獲得しました',
    oddNumber:'今日の残りの奇数',
    yesterdays:'昨日の手数料',
    User:'ユーザー ',
    getCommission:'コミッションを取得'
  },
  // my页面
  myPage:{
    logout:'サインアウト',
    areYouSureYouWantToLogOut:'ログアウトしてもよろしいですか？',
    confirnText:'もちろん',
    cancleText:'キャンセル',
    amazonReputation:'Amazon 評判:👑',
    accountBalance:'口座残高',
    recharge:'リチャージ',
    withdrawal:'引き出し額',
    deliveryAddress:'配送先住所',
    iWantToPromote:'宣伝したい',
    secondPurchaseRecord:'2回目の購入記録',
    accountDetails:'アカウントの詳細',
    teamReport:'チームレポート',
    companyQualification:'会社の資格',
	certificate: '証書'
  },
  addressPage:{
    address:' アドレス ',
    realName:'本名',
    pleaseEnterYourRealName:'本名を入力してください',
    mobileNumber:'携帯電話番号',
    pleaseEnterThePhoneNumber:'電話番号を入力してください',
    region:'地域',
    pleaseEnterTheRegion:'地域を入力してください',
    detailedAddress:'詳細な住所',
    pleaseEnterDetailedAddress:'詳細な住所を入力してください',
    submit:'送信'
  },
  // Popularize页面
  popularizePage:{
    iWantToPromote:' 宣伝したい ',
    inviteNewUsers:'新しいユーザーを招待します',
    winTheFutureWithYou:'あなたと一緒に未来を勝ち取ろう',
    screenshot:'スクリーンショット保存QRコード',
    friend:'友達の識別と登録',
    complete:'登録を完了し,注文を取得します',
    distribute:'24時間以内に現金の赤い封筒を配布する',
    invitationCode:'招待コード:'
  },
  accountDetailsPage:{
    accountDetails:' アカウントの詳細 ',
    startDate:'開始日',
    endDate:'終了日',
    search:'検索',
    alltypes:'すべてのタイプ',
    withdrawalRecord:'引き出し記録',
    rechargeRecord:'リチャージレコード',
    withDrawal:'引き出し',
    UAddress:'U アドレス',
    rebateForOrderGrabbing:'家政婦にひったくりする',
    orderGrab:'チケットをつかむ',
    recharge:'リチャージ',
    tryToLoad:'読み込みに苦労',
    noMore:'これ以上',
    cancel:'キャンセル',
    ok:'もちろん',
    pleaseSelectATime:'時刻を選択してください'
},
teamPage:{
  teamReport:' アカウントの詳細 ',
  startDate:'開始日',
  endDate:'終了日',
  search:'検索',
  teamBalance:'チームバランス($)',
  teamFlow:'チームフロー($)',
  totalTeamRecharge:'チーム全体のリチャージ($)',
  theTotalWithdrawalOfTheTeam:'チームの完全な撤退($)',
  teamOrderCommission:'チーム注文手数料($)',
  myCommission:'私の手数料($)',
  directPushNumber:'ダイレクトプッシュ番号',
  teamSize:'チームサイズ',
  todaysNewNumberOfPeople: '今日の新しい人数',
  todaysActiveNumber:'今日のアクティブな番号',
  level1:'レベル1',
  level2:'セカンダリ',
  level3:'レベル3',
  tryToLoad:'読み込みに苦労',
  noMore:'これ以上',
  mobilePhone:'携帯電話番号',
  Number:'プロモーションの数',
  withdrawals:'引き出し',
  recharge:'リチャージ',
  time:'登録時間'
},
culturePage:{
  title:' 会社の資格 ',
  text:'アマゾンは「顧客中心」の概念を守り、「毎日の低価格、本物の製品」の信念を守り、本、コンピューター、デジタル家電、母親と赤ちゃんのデパート、衣料品など、数千万の製品を販売しています。バッグなど。アマゾン中国は専門的なサービスを提供しています。正規のライセンス製品は毎日安価であり、機械で印刷された請求書は全国的に保証されています。代金引換、30日以内の返品。アマゾンは世界中の消費者に便利で速いオンラインショッピング体験を提供します'
},
// 充值页面
rechargePage:{
  recharge:'リチャージ',
  currentBalance:'現在の残高 ($)',
  rechargeApplication:'リチャージアプリケーション($)',
  accumulatedRecharged:'再充電された累積($)',
  rechargeAmount:'リチャージ量',
  pleaseEnterTheRechargeAmount:'リチャージ量を入力してください',
  rechargeNow:'今すぐ充電'
},
// 提现页面
withDrawalPage:{
  withDrawal:' 引き出し額 ',
  currentBalance:'現在の残高 ($)',
  withdrawalApplicationInProgress:'撤回申請中($)',
  cumulativelyWithdrawn:'累積的に撤回($)',
  withdrawalAmount:'引き出し額',
  pleaseEnterTheWithdrawalAmount:'出金金額を入力してください',
  withdrawAll:'すべて撤回',
  uAddress:'Uアドレス',
  pleaseEnterUAddress:'Uアドレスを入力してください',
  transactionPassword:'トランザクションパスワード',
  pleaseEnterTheTransactionPassword:'トランザクションパスワードを入力してください',
  withdrawNow:'今すぐ撤回',
  allFieldsCannotBeEmpty:'すべての入力を空にすることはできません',
  able:'引き出し可能な金額'
},
// 抢单列表页面
grabListPage:{
  failedToGetTheOrderGrabList:'プリエンプティブ リストの取得に失敗しました'
},
//   注册页
registerPage:{
  register:'登録 ',
  mobileNumber:'携帯電話番号',
  mobileNumberPlaceholder :'電話番号を入力してください',
  mobileNumberMessage:'電話番号を入力してください',
  loginPassword:'ログインパスワード',
  loginPasswordPlaceholder:'ログインパスワードを入力してください',
  loginPasswordMessage:'ログインパスワードを入力してください',
  invitationCode:'招待コード',
  invitationCodePlaceholder:'招待コードを入力してください',
  invitationCodeMessage:'招待コードを入力してください',
  fundingPassword:'資金調達パスワード',
  fundingPasswordPlaceholder:'ファンドのパスワードを入力してください',
  fundingPasswordMessage:'ファンドのパスワードを入力してください',
  iKnowAndAgree:'私は知っていて同意します',
  accountOpeningAgreement:'"口座開設契約"',
  theTreaties:'条約',
  signUpNow:'今すぐサインアップ',
  pleaseAgree:'まず登録契約に同意してください',
  existingAccount:'既存のアカウント',
  loginNow:'"今すぐログイン"',
  registrationFailed:'登録に失敗しました',
  registrationSuccessful:'登録は成功しました',
  iKnow:'わかっている',
  accountOpen:'口座開設契約'
},
	vipPage:{
		'vip_level': 'VIPレベル',
		'current_level': '現在のレベル',
		'vip_text1': '1日あたり{order_num}件の注文が取得できます',
		'vip_text2': '注文あたりの利益{rate}%',
		'vip_text3': 'USDTの1回の入金({recharge} USDTが有効になります)',
	}
	
}
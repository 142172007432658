export default {
    accountDetailsPage:{
        accountDetails:'AccountDetails',
        startDate:'Start Date',
        endDate:'End Date',
        search:'Search',
        alltypes:'All Types',
        withdrawalRecord:'Withdrawal Record',
        rechargeRecord:'Recharge Record',
        withDrawal:'WithDrawal',
        UAddress:'U Address',
        rebateForOrderGrabbing:'Rebate For Order Grabbing',
        orderGrab:'Order Grab',
        recharge:'Recharge',
        tryToLoad:'Try To Load',
        noMore:'No More',
        cancel:'Cancel',
        ok:'OK',
        pleaseSelectATime:'Please Select A Time'
    },
    teamPage:{
        teamReport:'Team Report',
        startDate:'Start Date',
        endDate:'End Date',
        search:'Search',
        teamBalance:'Team Balance($)',
        teamFlow:'Team Flow($)',
        totalTeamRecharge:'Total team recharge($)',
        theTotalWithdrawalOfTheTeam:'The total withdrawal of the team($)',
        teamOrderCommission:'Team order commission($)',
        myCommission:'My Commission($)',
        directPushNumber:'Direct Push Number',
        teamSize:'Team Size',
        todaysNewNumberOfPeople: 'Todays new number of people',
        todaysActiveNumber:'Todays active number',
        level1:'Level 1',
        level2:'Level 2',
        level3:'Level 3',
        tryToLoad:'Try To Load',
        noMore:'No More',
        mobilePhone:'Mobile Phone',
        Number:'Number of promoters',
        withdrawals:'Withdrawals',
        recharge:'Recharge',
        time:'Registration Time'
    },
    culturePage:{
        title:' Company Qualification ',
        text:'Amazon adheres to the concept of "customer-centric" and adheres to the belief of "everyday low prices, authentic products", and sells tens of millions of products such as books, computers, digital home appliances, mother and baby department stores, clothing bags and so on. Amazon China provides professional services: genuine licensed products are cheap every day, and machine-printed invoices are guaranteed nationwide. Cash on delivery, returns within 30 days. Amazon provides consumers around the world with a convenient and fast online shopping experience'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Mobile Number',
        mobileNumberPlaceholder:'Please enter your mobile phone number',
        mobileNumberMessage:'Please enter the standard mobile phone number',
        password:'Password',
        passwordPlaceholder:'Please enter a password',
        passwordMessage:'You entered the wrong password, please try again',
        forgotPassword:'Forgot Password',
        goToRegister:'Go To Register',
        rememberPassword:'Remember Password',
        login:'Login',
        noAccountYet:'No Account Yet?',
        loginSuccessful:'Login Successful!',
        loginFailed:'Login Failed!'
      },
    //   注册页
      registerPage:{
        register:' Register',
        mobileNumber:'Mobile Number',
        mobileNumberPlaceholder :'Please enter the phone number',
        mobileNumberMessage:'Please enter the standard mobile phone number',
        loginPassword:'Login Password',
        loginPasswordPlaceholder:'Please enter the login password',
        loginPasswordMessage:'The login password you set cannot be empty',
        invitationCode:'Invitation Code',
        invitationCodePlaceholder:'Please enter the invitation code',
        invitationCodeMessage:'You entered the invitation code incorrectly',
        fundingPassword:'Funding Password',
        fundingPasswordPlaceholder:'Please enter the fund password',
        fundingPasswordMessage:'The fund password you set cannot be empty',
        iKnowAndAgree:'I know and agree',
        accountOpeningAgreement:'"Account Opening Agreement"',
        theTreaties:'The Treaties',
        signUpNow:'Sign Up Now',
        pleaseAgree:'Please agree to the registration agreement first',
        existingAccount:'existing account',
        loginNow:'"Login now"',
        registrationFailed:'Registration failed',
        registrationSuccessful:'Registration Successful',
        iKnow:'I Know',
        accountOpen:'Account Opening Agreement'
      },
      // my页面
      myPage:{
        logout:'logout',
        areYouSureYouWantToLogOut:'Are you sure you want to log out?',
        confirnText:'OK',
        cancleText:'Cancle',
        amazonReputation:'Amazon reputation:👑',
        accountBalance:'Account Balance',
        recharge:'Recharge',
        withdrawal:'Withdrawal',
        deliveryAddress:'Delivery Address',
        iWantToPromote:'I want to promote',
        secondPurchaseRecord:'Second purchase record',
        accountDetails:'Account Details',
        teamReport:'Team Report',
        companyQualification:'Company Qualification',
		certificate: 'Certificate'
      },
      // 充值页面
      rechargePage:{
        recharge:'Recharge',
        currentBalance:'Current Balance ($)',
        rechargeApplication:'recharge application($)',
        accumulatedRecharged:'Accumulated recharged($)',
        rechargeAmount:'recharge amount',
        pleaseEnterTheRechargeAmount:'Please enter the recharge amount',
        rechargeNow:'Recharge now'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Failed to get the order grab list'
      },
      // 提现页面
      withDrawalPage:{
        withDrawal:'Withdrawal',
        currentBalance:'Current Balance ($)',
        withdrawalApplicationInProgress:'Withdrawal application in progress($)',
        cumulativelyWithdrawn:'Cumulatively withdrawn($)',
        withdrawalAmount:'Withdrawal Amount',
        pleaseEnterTheWithdrawalAmount:'Please enter the withdrawal amount',
        withdrawAll:'Withdraw all',
        uAddress:'U address',
        pleaseEnterUAddress:'Please enter U address',
        transactionPassword:'Transaction password',
        pleaseEnterTheTransactionPassword:'Please enter the transaction password',
        withdrawNow:'Withdraw now',
        allFieldsCannotBeEmpty:'All fields cannot be empty',
        able:'Amount of cash withdrawable'
    },
    // 主页页面
    indexPage:{
      indexList:'Failed to get home page data',
      amazonLetMiaogouOpenYourWayToWealth:'AmazonLet Miaogou open your way to wealth',
      myAssets:'My Assets($)',
      recharge:'Recharge',
      withdrawal:'Withdrawal',
      yesterdaysIncome:'Yesterdays Income($)',
      cumulativeIncome:'Cumulative Income($)',
      todaysIncome:'Todays income($)',
      freezeFunds:'Freeze funds($)',
      regularFinancialManagement:'regular financial management',
      quickRecharge:'Quick recharge',
      quickWithdrawal:'Quick Withdrawal',
      inviteFriends:'Invite Friends',
      taskHall:'Task Hall',
      unlocked:'Unlocked',
	  service:'Service',
	  leaderboard:'Leaderboard'
    },
    // 收货地址页面
    addressPage:{
      address:'Address',
      realName:'Real Name',
      pleaseEnterYourRealName:'Please enter your real name',
      mobileNumber:'Mobile Number',
      pleaseEnterThePhoneNumber:'Please enter the phone number',
      region:'Region',
      pleaseEnterTheRegion:'Please enter the region',
      detailedAddress:'Detailed Address',
      pleaseEnterDetailedAddress:'Please enter detailed address',
      submit:'Submit'
    },
    // order页面
    orderPage:{
      grabAnOrder:'Grab an order',
      hiShop:'Hi Shop',
      winTheFutureWithYou:'Win the future with you',
      orderText:'Founded in 1994, Amazon Marketplace cooperates with more than 1 million e-commerce companies and processes 150,000 to 30 million orders per day. Since fund management is entrusted to asset management companies, it can avoid user refund requests and e-commerce companies’ capital flight, The smart cloud client and user IP are used in accordance with the set rules. The automatic order competition will prevent merchants from being banned, downgraded, and banned due to the mistakes of users who grab orders. As an intermediary, we will continue to work hard to improve the trust of merchants and users when competing for orders Degree and efficiency!',
      bronzeMember:'Bronze Member',
      commission:'Commission({rate}%)',
      Matching:'Matching orders for you, please wait a moment',
      dispatch:'Dispatch',
      grabAnOrder:'Grab an order',
      balance:'Balance',
      recharge:'Recharge',
      withdrawal:'Withdrawal',
      matchingRules:'Matching rules',
      commissionEarnedToday:'Commission earned today',
      Today:'Today has grabbed the number of orders',
      oddNumber:'Todays remaining odd number',
      yesterdays:'Yesterdays commission',
      User:'User',
      getCommission:'Get commission'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:' I want to promote ',
      inviteNewUsers:'Invite new users',
      winTheFutureWithYou:'Win the future with you',
      screenshot:'Screenshot save QR code',
      friend:'Friend identification and registration',
      complete:'Complete the registration and grab the order',
      distribute:'Distribute cash red envelopes within 24 hours',
      invitationCode:'Invitation Code:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Second purchase record',
      this:'This data is provided by major cooperative officials',
      Remaining:'Remaining available assets($)',
      allOrders:'All orders',
      toBeProcessed:'To be processed',
      completed:'Completed',
      freezing:'Freezing',
      totalAmountOfTheOrder:'Total amount of the order',
      commission:'Commission',
      Estimated:'Estimated commission rebate',
      submitOrder:'Submit order',
      secondpurchasetime:'Second purchase time:',
      secondPurchaseNumber:'Second purchase number:',
      NotSubmitted:'Not submitted yet',
      submitNow:'Submit now'
    },
		
	vipPage:{
		'vip_level': 'VIP level',
		'current_level': 'Current level',
		'vip_text1': '{order_num} orders can be obtained per day',
		'vip_text2': '{rate}% profit per order',
		'vip_text3': 'A single deposit {recharge} USDT is activated',
	}
}
export default {
    accountDetailsPage:{
        accountDetails:'账户明细',
        startDate:'开始日期',
        endDate:'结束日期',
        search:'搜索',
        alltypes:'所有类型',
        withdrawalRecord:'提现记录',
        rechargeRecord:'充值记录',
        withDrawal:'提现',
        UAddress:'U地址',
        rebateForOrderGrabbing:'抢单返佣',
        orderGrab:'抢单',
        recharge:'充值',
        tryToLoad:'努力加载中',
        noMore:'没有更多',
        cancel:'取消',
        ok:'确定',
        pleaseSelectATime:'请选择时间'
    },
    teamPage:{
        teamReport:'团队报表',
        startDate:'开始日期',
        endDate:'结束日期',
        search:'搜索',
        teamBalance:'团队余额($)',
        teamFlow:'团队流水($)',
        totalTeamRecharge:'团队总充值($)',
        theTotalWithdrawalOfTheTeam:'团队总提现($)',
        teamOrderCommission:'团队订单佣金($)',
        myCommission:'我的佣金($)',
        directPushNumber:'直推人数',
        teamSize:'团队人数',
        todaysNewNumberOfPeople: '今日新增人数',
        todaysActiveNumber:'今日活跃人数',
        level1:'一级',
        level2:'二级',
        level3:'三级',
        tryToLoad:'努力加载中',
        noMore:'没有更多',
        mobilePhone:'手机号',
        Number:'推广人数',
        withdrawals:'提现',
        recharge:'充值',
        time:'注册时间'
    },
    culturePage:{
        title:'公司资质',
        text:'亚马逊坚持“以客户为中心”的理念，秉承“天天低价，正品行货”信念，销售图书、电脑、数码家电、母婴百货、服饰箱包等上千万种产品。亚马逊中国提供专业服务：正品行货天天低价，机打发票全国联保。货到付款，30天内可退换货。亚马逊为全球消费者提供便利、快捷的网购体验'
    },
    // 登录页
    loginPage:{
        mobileNumber:'手机号',
        mobileNumberPlaceholder:'请输入手机号码',
        mobileNumberMessage:'请输入规范的手机号码',
        password:'密码',
        passwordPlaceholder:'请输入密码',
        passwordMessage:'您输入的密码有误请重试',
        forgotPassword:'忘记密码',
        goToRegister:'去注册',
        rememberPassword:'记住密码',
        login:'登录',
        noAccountYet:'还没有账号?',
        loginSuccessful:'登录成功！',
        loginFailed:'登录失败！'
      },
    //   注册页
      registerPage:{
        register:' 注册',
        mobileNumber:'手机号',
        mobileNumberPlaceholder :'请输入手机号',
        mobileNumberMessage:'请输入规范的手机号码',
        loginPassword:'登录密码',
        loginPasswordPlaceholder:'请输入登录密码',
        loginPasswordMessage:'您设置的登录密码不能为空',
        invitationCode:'邀请码',
        invitationCodePlaceholder:'请输入邀请码',
        invitationCodeMessage:'您输入的邀请码有误',
        fundingPassword:'资金密码',
        fundingPasswordPlaceholder:'请输入资金密码',
        fundingPasswordMessage:'您设置的资金密码不能为空',
        iKnowAndAgree:'我已知晓并同意',
        accountOpeningAgreement:'"开户协议"',
        theTreaties:'各项条约',
        signUpNow:'立即注册',
        pleaseAgree:'请先同意注册协议',
        existingAccount:'已有帐号',
        loginNow:'"马上登录"',
        registrationFailed:'注册失败',
        registrationSuccessful:'注册成功',
        iKnow:'我知道',
        accountOpen:'开户协议'
      },
      // my页面
      myPage:{
        logout:'退出登录',
        areYouSureYouWantToLogOut:'是否确定退出登录？',
        confirnText:'确定',
        cancleText:'取消',
        amazonReputation:'Amazon 信誉:👑',
        accountBalance:'账户余额',
        recharge:'充值',
        withdrawal:'提现',
        deliveryAddress:'收货地址',
        iWantToPromote:'我要推广',
        secondPurchaseRecord:'秒购记录',
        accountDetails:'账户明细',
        teamReport:'团队报表',
        companyQualification:'公司资质',
		certificate: '证书'
      },
      // 充值页面
      rechargePage:{
        recharge:'充值',
        currentBalance:'当前余额($)',
        rechargeApplication:'充值申请中($)',
        accumulatedRecharged:'累计已充值($)',
        rechargeAmount:'充值金额',
        pleaseEnterTheRechargeAmount:'请输入充值数量',
        rechargeNow:'立即充值'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'抢单列表获取失败'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'提现',
       currentBalance:'当前金额($)',
       withdrawalApplicationInProgress:'提现申请中($)',
       cumulativelyWithdrawn:'累计已提现($)',
       withdrawalAmount:'提现金额',
       pleaseEnterTheWithdrawalAmount:'请输入提现金额',
       withdrawAll:'全部提现',
       uAddress:'U地址',
       pleaseEnterUAddress:'请输入U地址',
       transactionPassword:'交易密码',
       pleaseEnterTheTransactionPassword:'请输入交易密码',
       withdrawNow:'立即提现',
       allFieldsCannotBeEmpty:'所有填写项不能为空',
       able:'可提现金额'
   },
      // 主页页面
    indexPage:{
      indexList:'获取首页数据失败',
      amazonLetMiaogouOpenYourWayToWealth:'Amazon让秒购开放您的财富之路',
      myAssets:'我的资产($)',
      recharge:'充值',
      withdrawal:'提现',
      yesterdaysIncome:'昨日收益($)',
      cumulativeIncome:'累计收益($)',
      todaysIncome:'今日收益($)',
      freezeFunds:'冻结资金($)',
      regularFinancialManagement:'定期理财',
      quickRecharge:'快速充值',
      quickWithdrawal:'快速提现',
      inviteFriends:'邀请好友',
      taskHall:'任务大厅',
      unlocked:'已解锁',
	  service:'客服',
	  leaderboard:'提现记录'
    },
    addressPage:{
      address:'地址',
      realName:'真实姓名',
      pleaseEnterYourRealName:'请输入真实姓名',
      mobileNumber:'手机号',
      pleaseEnterThePhoneNumber:'请输入手机号',
      region:'地区',
      pleaseEnterTheRegion:'请输入地区',
      detailedAddress:'详细地址',
      pleaseEnterDetailedAddress:'请输入详细地址',
      submit:'提交'
    },
    // order页面
    orderPage:{
      grabAnOrder:'抢单不停',
      hiShop:'嗨购',
      winTheFutureWithYou:'与您共赢未来',
      orderText:'Amazon Marketplace 成立于 1994&nbsp;年，与超过 100 万家电子商务公司合作，每天处理 15 万至 3000 万个订单。由于资金管理委托给资产管理公司，因此可以避免用户退款请求和电子商务公司的资金外逃，智能云客户端和用户IP按照设定的规则使用。自动下单竞赛将防止商家因抢单用户的失误而被封号、降级、封号。作为中介，我们将继续努力提高商家和用户在争夺订单时的信任度和效率！',
      bronzeMember:'青铜会员',
      commission:'佣金({rate}%)',
      Matching:'正在为您匹配订单请稍后',
      dispatch:'派单',
      grabAnOrder:'抢单',
      balance:'余额',
      recharge:'充值',
      withdrawal:'提现',
      matchingRules:'匹配规则',
      commissionEarnedToday:'今日已赚佣金',
      Today:'今日已抢单数',
      oddNumber:'今日剩余单数',
      yesterdays:'昨日佣金',
      User:'用户',
      getCommission:'获得佣金'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'我要推广',
      inviteNewUsers:'邀请新用户',
      winTheFutureWithYou:'与您共赢未来',
      screenshot:'截图保存二维码',
      friend:'好友识别并注册',
      complete:'完成注册抢单',
      distribute:'24小时内发放现金红包',
      invitationCode:'邀请码:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'秒购记录',
      this:'本数据由各大合作官方提供',
      Remaining:'剩余可用资产($)',
      allOrders:'全部单',
      toBeProcessed:'待处理',
      completed:'已完成',
      freezing:'冻结中',
      totalAmountOfTheOrder:'订单总余额',
      commission:'佣金',
      Estimated:'预计返佣',
      submitOrder:'提交订单',
      secondpurchasetime:'秒购时间:',
      secondPurchaseNumber:'秒购编号:',
      NotSubmitted:'暂不提交',
      submitNow:'立即提交'
    },
	vipPage:{
		'vip_level': 'VIP 等级',
		'current_level': '当前级别',
		'vip_text1': '每日可以获取 {order_num} 个订单',
		'vip_text2': '每个订单可获 {rate}% 利润',
		'vip_text3': '单笔充值 {recharge} USDT 激活',
	}
}